<template>
  <div>
    <div class="jumbotron-wrapper color">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5">
          <div class="row gx-5">
            <header class="mb-4">
              <p class="fs-1 fw-bolder mb-1">
                非凸算法正式上线国信证券，携手为量化交易赋能
              </p>
              <div class="fst-italic mb-2">2023年7月28日</div>
              <a
                class="badge bg-secondary text-decoration-none link-light"
                href="#!"
                >新闻</a
              >
              <a
                class="ms-1 badge bg-secondary text-decoration-none link-light"
                href="#!"
                >国信证券</a
              >
            </header>
          </div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="py-5">
        <div class="container-lg px-4">
          <div class="row">
            <div class="col-lg-3">
              <div class="d-flex align-items-center mt-lg-5 mb-4">
                <img
                  class="img-fluid rounded-circle logo_50"
                  src="../../assets/img/logo/logo_50.jpeg"
                  alt="非凸科技"
                />
                <div class="ms-3">
                  <div class="fw-bold">非凸新闻</div>
                  <div class="text-muted">Non-convex News</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <article class="lh-lg">
                <section class="mb-5">
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/51/01-国信.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    近期，非凸智能算法正式上线国信证券Grade，双方携手用超强算力、AI技术、精细服务等为量化交易赋能。基于项目方案的高成熟度，非凸科技快速完成了部署、测试及上线，满足了券商快速展业的迫切需求。
                  </p>
                  <p>
                    国信证券坚持走科技赋能路线，其智能算法中心主要为客户提供以成交为目的的自动化交易执行解决方案，采用算法总线一体化，搭载非凸算法在内的主动算法、日内算法、被动算法、场景化算法等，帮助客户提升执行效率，真正做到科技与交易的紧密结合。
                  </p>
                  <p>
                    大模型时代，算力需求持续释放。越来越多机构意识到，算法交易的能力输出需要强连续性、高可靠性以及优异的业务表现，而这其中需要超大规模算力支撑。非凸科技长期深耕于算力基础设施建设，每年持续加码研发投入，自建AI超算中心，实现模型及策略的高效迭代，保证了算法的持续有效。除此之外，非凸科技将根据客户需求提供定制化、差异化服务，为机构业务发展提供长期可靠的深度算力资源。
                  </p>
                  <p>
                    在与国信证券的项目对接中，非凸科技多次感受到他们对算法和交易的深度理解。从业务需求明确到全市场算法考量，再到全方位供应商考察，国信证券始终将用户的实际需求放在首位，真正做到专业的交易工具和用户服务。非凸科技将充分发挥自身优势，助力国信证券为机构客户提供更多精细化服务。针对机构客户，非凸科技能够全面协助券商提供更加完整的交易观察、周期报告、调整跟进等，持续优化算法交易体验。
                  </p>
                  <p>
                    目前，非凸科技已与国信证券在内的多家头部券商达成总对总合作，凭借高绩效、稳运行、强技术获得券商及管理人的高度认可。
                  </p>
                  <p>
                    期待与更多券商展开合作，非凸科技也将持续通过金融科技力量真正赋能业务开展，在互动过程中，与客户共同发展、实现卓越。
                  </p>
                </section>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "News51",
};
</script>

<style></style>
